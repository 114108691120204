@use "../../common" as common;

.highlight {
  color: common.$main-color;
  text-shadow: 0 0 1em common.$main-color;
  font-weight: 700;
  &.highlight--is-special {
    @media only screen and (min-width: common.$screen-medium) {
      background-color: white;
      box-shadow: 0 0 1em rgba(white, 0.7);
      color: common.$background-color;
      text-shadow: none;
      padding: 0.3em 0.5em 0.3em 0.5em;
      &.highlight--is-date {
        display: inline-block;
        margin-top: -1.2em;
      }
    }
  }

  &.rojo {
    color: #ff0000;
    text-shadow: 0 0 1em #ff0000;
  }
}
