@use "../../common" as common;

.item {
  position: relative;
  margin-bottom: 2vw;
  border-right: 2px solid rgba(common.$other-color, 0.5);
  padding: 1vw 2vw 1vw 2vw;
  position: relative;
  &:last-child {
    border-right: none;
  }
  &:after {
    content: "";
    background-color: common.$other-color;
    /* width: 18vw;
      height: 2px;
      margin-top: 2vw;*/
    height: 10px;
    border-radius: 5px;
    translate: 50% -50%;
    width: 10px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: -1px;
  }
  &:last-of-type::after {
    display: none;
  }

  p {
    padding: 0;
    font-size: 1.2vw;
    line-height: 1.7em;
    color: common.$complementary-color;
    margin: 0;
  }
}

@media only screen and (max-width: common.$screen-medium) {
  .item {
    margin-bottom: 31px;
    border-right: none;
    max-width: 500px;
    &:after {
      border-radius: 0;
      height: 2px;
      width: 276px;
      position: relative;
      right: 0;
      top: 0;
      translate: 0 0;
      margin-top: 31px;
      opacity: 0.5;
    }

    p {
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: common.$screen-small) {
  .item {
    margin-bottom: 4.49vw;
    &:after {
      width: 40vw;

      margin-top: 4.49vw;
    }

    p {
      font-size: 3.62vw;
    }
  }
}
