@use "../common" as common;

*::selection {
  color: common.$background-color;
  background: white;
}

h1 {
  text-align: center;
  color: common.$main-color;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 4vw;
  text-shadow: 0 0 1em common.$main-color;
  position: relative;
  opacity: 0;
  animation: fade-in 0.5s ease-out forwards;
  &::before {
    content: "";
    display: inline-block;
    width: 56vw;
    height: 2px;
    background-color: common.$other-color;
    position: absolute;
    bottom: -0.3em;
    left: 22vw;
    opacity: 0.5;
  }
}

h3 {
  text-align: center;
  font-family: "Roboto", sans-serif;
  color: common.$complementary-color;
  text-shadow: 0 0 1em common.$complementary-color;
  font-weight: 300;
  font-size: 2.4vw;
  line-height: 1.4em;
  padding: 0 2vw 0 2vw;
  margin-bottom: 1.71em;
  opacity: 0;
  animation: fade-in 1.5s ease-out forwards;
  animation-delay: 0.2s;
}

.content {
  display: grid;
  gap: 1.85vw;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  opacity: 0;
  animation: fade-in 1s ease-out forwards;
  animation-delay: 0.4s;
}

.comparacion {
  font-family: "Roboto", sans-serif;
  padding: 3.35vw 12vw 8vw 12vw;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, 22vw);
  justify-content: center;
  opacity: 0;
  animation: fade-in 1s ease-out forwards;
  animation-delay: 0.64s;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: common.$screen-medium) {
  .app {
    display: grid;
    justify-items: center;
  }
  h1 {
    font-size: 40px;
    &::before {
      transform: translateX(-50%);
      width: 570px;
      left: 50%;
    }
  }

  h3 {
    font-size: 30px;
    padding: 0 31px 0 31px;
    width: 500px;
    left: 50%;
  }

  .content {
    gap: 28px;
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    width: 491px;
  }

  .comparacion {
    padding: 120px 0 123px 0;
    grid-template-columns: 80vw;
    justify-items: center;
    br {
      display: none;
    }
  }
}

@media only screen and (max-width: common.$screen-small) {
  h1 {
    font-size: 6.8vw;
    &::before {
      width: 90vw;
    }
  }

  h3 {
    font-size: 6vw;
    padding: 0 2vw 0 2vw;
    width: 90vw;
  }

  .content {
    gap: 14vw;
    grid-template-columns: 1fr;
    width: 71.16vw;
  }

  .comparacion {
    padding: 17.39vw 0 17.83vw 0;
  }
}
