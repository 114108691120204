@use "../../common" as common;

$circle-diameter: 17vw;

@mixin svg-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 1.3;
}

.circle {
  border-radius: 50%;
  width: $circle-diameter;
  height: $circle-diameter;
  display: grid;
  align-content: center;
  text-align: center;
  position: relative;

  &__main-text {
    font-size: 4vw;
    font-weight: 900;
    color: common.$main-color;
    padding: 0;
    margin: 0;
    text-shadow: 0 0 1em common.$main-color;
  }

  &__number {
    position: relative;
  }

  &__unit {
    color: common.$main-color;
    line-height: 4vw;
    font-size: 2vw;
    position: absolute;
    bottom: 0;
    right: -0.9em;
  }

  &__bottom-text {
    font-size: 1.6vw;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: common.$complementary-color;

    &--small {
      font-size: 0.8vw;
      margin: 0 2.2vw 0 2.2vw;
    }
  }

  .circle__background {
    @include svg-circle();
    stroke: rgba(common.$other-color, 0.2);
  }

  .circle__percentage {
    @include svg-circle();
    stroke: rgba(white, 0.8); //common.$main-color;
    filter: drop-shadow(0 0 1em common.$main-color);
    &--escondido {
      display: none;
    }
  }

  .circle__percentage-path {
    animation: progress 1.5s ease-out forwards;
    animation-delay: 0.4s;
  }
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }

  &.circle--highlight {
    .circle__background {
      fill: common.$complementary-color;
      stroke: common.$complementary-color;
      filter: drop-shadow(0 0 2em rgba(white, 0.5));
    }
    *::selection {
      color: common.$complementary-color;
      background: common.$background-color;
    }
    .circle__main-text {
      color: common.$background-color;
      text-shadow: none;
    }
    .circle__bottom-text {
      color: common.$background-color;
      z-index: 7;
    }
    .circle__unit {
      color: common.$background-color;
    }
  }

  @media only screen and (max-width: common.$screen-medium) {
    width: 262px;
    height: 262px;

    &__main-text {
      font-size: 61px;
    }

    &__unit {
      line-height: 61px;
      font-size: 31px;
    }

    &__bottom-text {
      font-size: 25px;

      &--small {
        font-size: 12px;
        margin: 0 49px 0 49px;
      }
    }
  }

  @media only screen and (max-width: common.$screen-small) {
    width: 76.04vw;
    height: 76.04vw;

    &__main-text {
      font-size: 17.68vw;
    }

    &__unit {
      line-height: 17.68vw;
      font-size: 8.98vw;
    }

    &__bottom-text {
      font-size: 7.24vw;

      &--small {
        font-size: 3.48vw;
        margin: 0 14vw 0 14vw;
      }
    }
  }
}
